
































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'Chem1180A8Q1',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      question1: `Below, the polarity arrows for $\{HCl}$ and $\{BF}_3$ are broken down into their x and y components.`,
      polarImage: '/img/assignments/Chem1180A8Q1Polar.png',
      nonPolarImage: '/img/assignments/Chem1180A8Q1NonPolar.png',
      inputs: {
        studentExplanation: null,
      },
      questions: [
        {
          text: 'What information do you need to know about a molecule in order to determine if it is polar or not?',
          inputName: 'studentExplanation',
        },
      ],
    };
  },
});
